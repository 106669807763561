import Vue from 'vue';
import store from '@/store';
import axios from '@/plugins/axiosV2';
import utils from '@/helpers/utilidades';

const http = axios(process.env.VUE_APP_API_GARANTIAS);

export default {
	obtener_garantias(options, filtro=null) {
		return http.get(utils.url_options(`/portal_activos/${filtro}`,options));
	},
	guardar_garantia(payload){
		return http.post('/portal_activos/guardar_garantia', payload);
	},
	editar_garantia(id, payload){
		return http.put(`/portal_activos/editar_garantia/${id}`, payload);
	},
	cargar_imagen(id, payload){
		return http.post(`/portal_activos/cargar_imagen/${id}`, payload);
	},
	descargar_imagen(id){
		return http.get(`/portal_activos/descargar_imagen/${id}`,{responseType: 'blob'});
	},
	editar_imagen(id, payload){
		return http.put(`/portal_activos/editar_imagen/${id}`, payload);
	},
	obtener_garantia(id){
		return http.get(`/portal_activos/obtener_garantia/${id}`);
	},
	eliminar_imagen(id) {
		return http.delete(`/portal_activos/eliminar_imagen/${id}`);
	},
	importar_imagenes(solicitud_id, garantia_id){
		return http.get(`portal_activos/importar_imagenes/${solicitud_id}/${garantia_id}`);
	},
	importar_excel(payload){
		return http.post('portal_activos/importar_excel', payload);
	},
	obtener_direccion(solicitud_id){
		return http.get(`/portal_activos/importar/direccion/${solicitud_id}`);
	},	
	mostrar_garantias(solicitud_id){
		return http.get(`/portal_activos/obtener/garantias/${solicitud_id}`);
	},
	obtener_detalles_garantia(solicitud_id, garantia_id){
		return http.get(`/portal_activos/importar/detalles/${solicitud_id}/${garantia_id}`);
	},
}

